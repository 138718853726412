<template>
  <div>
    <Header>客服/常见问题</Header>
    <div class="content">
      <div v-html="question"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    question() {
      return this.$store.state.anyInfo.question;
    },
  },
  mounted() {
    if (!this.question) {
      this.$api.anyInfo().then((res) => {
        if (res.code == 0) {
          this.$store.commit('SET_ANYINFO', res.data);
        }
      });
    }
  },
};
</script>

<style scoped lang="less">
.content {
  padding: 20px;
  font-size: 16px;
  img {
    max-width: 100%;
  }
}
</style>
